import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { Label } from '@radix-ui/react-label';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useEffect, useState, type KeyboardEventHandler } from 'react';

import { type UserSearchResult } from '@f4s/types';

import { useUser } from '@/api-queries/app-settings';
import { useMeQuestionnaires } from '@/api-queries/questionnaires';
import { Popover } from '@/components/common/popover/popover';
import { UserProfile } from '@/components/common/profile/user-profile';
import { ConnectionsContext, useAppContext } from '@/contexts';
import { SpinnerSmallIcon } from '@/icons';
import { useUserSearch } from '@/lib/hooks/useUserSearch';
import { formatName } from '@/lib/i18n';
import { Validations } from '@/lib/utils/validations';

import { type SelectableTeamMember } from '../types';
import { getTeamMemberKey } from '../utils';

const MAX_RESULTS = 8;

type Props = {
  label: string;
  placeholder: string;
  excludeMembers: SelectableTeamMember[];
  onUserSelect: (user: SelectableTeamMember) => void;
  /* the org that is selected in this search context */
  orgId?: number;
  includeMeOverTime?: boolean;
};
// type EmailOption = { type: 'email'; email: string };
// type UserOption = { type: 'user'; user: UserSearchResult };
// type MeOverTimeOption = { type: 'meOverTime'; questionnaire: Questionnaire };
// export type SearchOption = UserOrEmail | MeOverTimeOption;

function getLoopedIndex(index: number, totalItems: number, maxItems: number) {
  const limit = Math.min(maxItems, totalItems);
  return Math.abs((index + 100 * limit) % limit);
}

export const UserSearch = ({
  label,
  placeholder,
  onUserSelect,
  excludeMembers,
  orgId,
  includeMeOverTime = false,
}: Props) => {
  const { user } = useUser();
  const [query, setQuery] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [focusOffset, setFocusOffset] = useState<number>(0);

  const excludeMemberIds = excludeMembers.map(getTeamMemberKey);

  const {
    results: querySearchResults,
    isNoResults,
    isLoading,
  } = useUserSearch({ query, excludeMembers, maxResults: MAX_RESULTS, orgId });

  const { data: meQuestionnaires = [] } = useMeQuestionnaires({
    enabled: includeMeOverTime,
  });

  const searchMembers: { type: 'user'; user: UserSearchResult }[] = querySearchResults
    ? querySearchResults.map((queryUser) => ({
        type: 'user',
        user: {
          id: queryUser.userId,
          userId: queryUser.userId,
          firstName: queryUser.firstName,
          lastName: queryUser.lastName,
          profileImageUrl: queryUser.profileImageUrl || '',
          isConnected: queryUser.isConnected || false,
        },
      }))
    : [];

  const pastQuestionnaires = meQuestionnaires
    .sort((a, b) => Date.parse(b.completedAt) - Date.parse(a.completedAt))
    .slice(1);
  const meOverTimeOptions = pastQuestionnaires.map((q) => ({
    type: 'meOverTime' as const,
    questionnaire: q,
    user,
  }));

  // Only email result is displayed when query is an email and no matching email user is found
  const isEmailOnly =
    Validations.email(query) && (searchMembers?.length || 0) === 0 && !isLoading;

  const resultsDisplayed: SelectableTeamMember[] = [
    ...(isEmailOnly ? [{ type: 'email' as const, email: query }] : []),
    ...searchMembers,
    ...(includeMeOverTime ? meOverTimeOptions : []),
  ]
    .filter((result) => {
      const id = getTeamMemberKey(result);
      return !excludeMemberIds.includes(id);
    })
    .slice(0, MAX_RESULTS);

  const focusIndex = getLoopedIndex(focusOffset, resultsDisplayed.length, MAX_RESULTS);

  useEffect(() => {
    if (query.length > 1 && resultsDisplayed.length > 0) {
      setIsOpen(true);
    } else setIsOpen(false);
  }, [query.length, resultsDisplayed.length]);

  const handleUserSelect = (selectedUser: SelectableTeamMember) => {
    setIsOpen(false);
    setQuery('');
    onUserSelect(selectedUser);
    setFocusOffset(0);
  };
  const handleInputSubmit = () => {
    if (resultsDisplayed.length > 0 && !Number.isNaN(focusIndex))
      handleUserSelect(resultsDisplayed[focusIndex]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusOffset((value) => value + 1);
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusOffset((value) => value - 1);
    }

    if (event.key === 'Tab') {
      event.preventDefault();
      if (event.shiftKey) setFocusOffset((value) => value - 1);
      else setFocusOffset((value) => value + 1);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      handleInputSubmit();
    }
  };

  return (
    <fieldset className="mb-2">
      <Label className="relative font-normal">
        <span className="mb-2 block text-sm font-medium text-gray-700">{label}</span>
        <div className="relative flex w-full items-center">
          {isLoading && (
            <SpinnerSmallIcon className="text-muted-foreground absolute right-0 z-10 mx-5 h-5 animate-spin" />
          )}
          {isNoResults && (
            <ExclamationCircleIcon className="text-muted-foreground absolute right-0 z-10 mx-5 h-5" />
          )}
          <input
            type="text"
            name="User search"
            className="h-10 w-full rounded-lg border border-gray-300 pb-2 text-sm leading-none placeholder-gray-500"
            placeholder={placeholder}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={(event) => handleKeyDown(event)}
            onFocus={(event) => {
              if (resultsDisplayed?.length) {
                setIsOpen(true);
                event.stopPropagation(); // Prevent onFocusOutside event on Popover closing instantly
              }
            }}
          />
        </div>
        <Popover
          open={isOpen}
          autoFocus={false}
          fullWidth
          offset={2}
          align="start"
          onPointerDownOutside={() => setIsOpen(false)}
          trigger=""
        >
          {isOpen && resultsDisplayed.length > 0 && (
            <SearchResultDropdown
              results={resultsDisplayed}
              onUserSelect={handleUserSelect}
              focusIndex={focusIndex}
              setFocusIndex={setFocusOffset}
              orgId={orgId}
            />
          )}
        </Popover>
      </Label>
    </fieldset>
  );
};

function SearchResultDropdown({
  results,
  onUserSelect,
  focusIndex,
  setFocusIndex,
  orgId,
}: {
  results: SelectableTeamMember[];
  onUserSelect: (user: SelectableTeamMember) => void;
  focusIndex: number;
  setFocusIndex: (index: number) => void;
  /* the org that is selected in this search context */
  orgId?: number;
}) {
  const { user } = useUser();
  const connections = useAppContext(ConnectionsContext);

  const getFirstOrgInCommon = (result: SelectableTeamMember) => {
    if (result.type !== 'user') return null;
    const connectionUser = connections.users.find((c) => c.userId === result.user.userId);
    // If we're searching in the context of an org, search it first so the name matches
    if (
      orgId &&
      connectionUser?.orgs.some(({ organizationId }) => organizationId === orgId)
    ) {
      return user.organizations?.find((o) => o.id === orgId);
    }
    // else search full list for the first matching org
    return user.organizations?.find((myOrg) =>
      connectionUser?.orgs.some(({ organizationId }) => organizationId === myOrg.id),
    );
  };

  const scoreResultForSort = (result: SelectableTeamMember) => {
    if (result.type !== 'user') return 3;
    if ('isConnected' in result.user && result.user.isConnected) return 0;
    if (getFirstOrgInCommon(result)) return 1;
    return 2;
  };

  const renderResult = (result: SelectableTeamMember) => {
    if (result.type === 'email') {
      return <span>{result.email}</span>;
    }
    if (result.type === 'meOverTime') {
      return (
        <>
          <UserProfile user={user} />
          <span>{formatName('full', result.user)} (me over time)</span>
          <span className="text-muted-foreground ml-auto text-xs">
            {DateTime.fromISO(result.questionnaire.completedAt).toLocaleString()}
          </span>
        </>
      );
    }

    const org = getFirstOrgInCommon(result);

    return (
      <>
        <UserProfile user={result.user} />
        <span>{formatName('full', result.user)}</span>
        <span className="text-muted-foreground ml-auto text-xs">
          {org
            ? `From ${org.name}`
            : 'isConnected' in result.user && result.user.isConnected
              ? 'Personal Connection'
              : ''}
        </span>
      </>
    );
  };

  return (
    <div className="absolute z-[101] mt-1 w-full rounded-lg bg-white p-1 shadow-lg">
      {results
        .sort((a, b) => scoreResultForSort(a) - scoreResultForSort(b))
        .map((result, index) => (
          <button
            key={getTeamMemberKey(result)}
            type="button"
            onClick={() => onUserSelect(result)}
            onMouseEnter={() => setFocusIndex(index)}
            className={clsx(
              'flex h-10 w-full items-center gap-4 rounded p-2 text-left outline-none',
              { 'bg-gray-100': focusIndex === index },
            )}
          >
            {renderResult(result)}
          </button>
        ))}
    </div>
  );
}
