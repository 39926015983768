import { type OrgInviteStatus, type OrgPermission } from '@f4s/types';

import { httpAgent as http } from './agents/http';

export const get = (id: number) => http().get(`/api/v1/organizations/${id}`);

export const update = (id: number, data: { name?: string; logo?: string }) =>
  http().put(`/api/v1/organizations/${id}`, data);

export const create = (data: {
  billingEmail?: string;
  logo?: string;
  members: any[];
  name: string;
  type: 'space' | 'enterprise';
}) => http().post('/api/v3/organizations', data);

export const getGroups = (id: number) => http().get(`/api/v1/organizations/${id}/groups`);

export const getMembers = (id: number) =>
  http().get(`/api/v1/organizations/${id}/members`);

export const getViewableMembers = (id: number, page = 1) =>
  http().get(`/api/v1/organizations/${id}/viewable?data=member&page=${page}`);

export const getViewableGroups = (id: number, page = 1) =>
  http().get(`/api/v1/organizations/${id}/viewable?data=group&page=${page}`);

export const updateMembersRole = (id: number, data: any[]) =>
  http().put(`/api/v1/organizations/${id}/members/role`, data);

export const addMembers = (id: number, data: any[]) =>
  http().put(`/api/v3/organizations/${id}/members`, data);

export const addMembersCSV = (id: number, data: any[]) =>
  http().put(`/api/v1/organizations/${id}/members/csv`, data);

export const removeMember = (orgId: number, memberId: number) =>
  http().delete(`/api/v4/workspaces/${orgId}/members/${memberId}`);
// http().delete(`/api/v1/organizations/${id}/members/${memberId}`);

export const updatePermissions = (id: number, data: Partial<OrgPermission>) =>
  http().put(`/api/v1/organizations/${id}/permissions`, data);

export const updateAvatar = (id: number, data: { imageURL: string }) =>
  http().put(`/api/v1/organizations/${id}/avatar`, data);

export const memberStatus = (id: number, data: { status: string; from: string }) =>
  http().put(`/api/v1/organizations/${id}/members/status`, data);

/* -------------------------------------------------------------------------- */
/*                               Invite Related                               */
/* -------------------------------------------------------------------------- */
export const remind = (id: number, memberId: number) =>
  http().put(`/api/v1/organizations/${id}/members/${memberId}/remind`);

export const updateRequest = (
  id: number,
  data: { memberId: number; status: string } | { userId: number; status: string },
) => http().put(`/api/v1/organizations/${id}/members/request`, data);

export const join = (id: number, data?: { token: string }) =>
  http().put(`/api/v1/organizations/${id}/join`, data);

export const getInvitation = (token: string) =>
  http().get(`/api/v1/organizations/invite?token=${token}`);

export const acceptInvite = (token: string) =>
  http().put('/api/v1/organizations/invite', {
    status: 'approved',
    token,
  });

export const declineInvite = (token: string) =>
  http().put(`/api/v1/organizations/invite`, {
    status: 'declined',
    token,
  });

export const updateInvite = (data: { token: string; status: string }) =>
  http().put('/api/v1/organizations/invite', data);

export const deactiveInviteLink = (id: number, token: string) =>
  http().put(`/api/v1/organizations/${id}/invite-link`, { token, status: 'disable' });

export const activeInviteLink = (id: number, token: string) =>
  http().put(`/api/v1/organizations/${id}/invite-link`, { token, status: 'enable' });

export const createInviteLink = (id: number) =>
  http().post(`/api/v1/organizations/${id}/invite-link`);

export const getInviteLink = (id: number) =>
  http().get(`/api/v1/organizations/${id}/invite-link`);

export const verifyToken = (token: string) =>
  http().get(`/api/v1/organizations/verify/${token}`);

export const checkName = (orgName: string) =>
  http().post('/api/v1/organizations/verify/name', { orgName });

export const sendQuestionnaireReminders = ({
  orgId,
  memberIds,
  text,
}: {
  orgId: number;
  memberIds: number[];
  text: string;
}) =>
  http().post(`/api/v1/organizations/${orgId}/send-questionnaire-reminders`, {
    memberIds,
    text,
  });

export const dismissActivationComponent = (orgId: number) =>
  http().delete(`/api/v3/organizations/${orgId}/activationComponent`);

export const getApprovedOrInvitedMembers = (orgId: number) =>
  http().get<{ userId: number; status: OrgInviteStatus }[]>(
    `/api/v3/organizations/${orgId}/approved-or-invited-members`,
  );

export const getReducedOrganizationByMagicLinkToken = (token: string) =>
  http().get(`/api/v3/public/organizations/magic-link/${token}`);
