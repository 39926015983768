import { z, type Schema } from 'zod';

type ObjectName = 'monetizationLock';

const monetizationLockBaseSchema = z.object({
  type: z.enum(['banner', 'page', 'paywall']),
  feature: z.enum(['queries', 'boards', 'assessments', 'permissions']),
  name: z.string().optional(),
});

type MonetizationLockEventSchema = z.infer<typeof monetizationLockBaseSchema>;

const monetizationSchemas = {
  monetizationLock_viewed: monetizationLockBaseSchema,
  monetizationLock_clicked: monetizationLockBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { monetizationSchemas, type MonetizationLockEventSchema };
