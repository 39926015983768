import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type Organization } from '@f4s/types';

import { httpAgent as http } from '@/apis/agents/http';
import { errorHandler } from '@/lib/utils/error-handler';
import * as API from 'APIs';

export const useOrganizationMembersList = (orgId: number) =>
  useQuery({
    queryKey: ['org-members', { orgId }],
    queryFn: () =>
      API.organizations.getViewableMembers(orgId).then((res) => res.data.orgMembers),
  });

export const useOrganizationTeamsList = (orgId: number) =>
  useQuery({
    queryKey: ['org-teams', { orgId }],
    queryFn: () =>
      API.organizations.getViewableGroups(orgId).then((res) => res.data.orgGroups),
  });

export const useOrganization = (orgId: number | undefined, failSilently?: boolean) =>
  useQuery({
    queryKey: ['org', { orgId }],
    queryFn: async (): Promise<Organization> => {
      const res = await API.organizations.get(orgId!);
      return res.data;
    },
    onError: (err) => {
      if (!failSilently) errorHandler(err);
    },
    enabled: !!orgId,
  });

export function useOrganizationInvalidation(orgId?: number) {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(['org', { orgId }]);
}

export const useGetInvitation = ({
  token,
  enabled,
}: {
  token: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: ['org-invitation', { token }],
    queryFn: () => API.organizations.getInvitation(token).then((res) => res.data),
    onError: (err) => errorHandler(err),
    enabled,
  });

export const useAcceptInvitation = (token: string) => {
  const client = useQueryClient();
  return useMutation(API.organizations.acceptInvite, {
    mutationKey: ['org-accept-invitation', token],
    onError: (err) => errorHandler(err),
    onSuccess: () => {
      client.invalidateQueries(['comparison.questionnaires.get']);
      client.invalidateQueries(['comparison.questionnaires.get.aggregates']);
      client.invalidateQueries(['connections']);
    },
  });
};

export const useDeclineInvitation = (token: string) =>
  useMutation(API.organizations.declineInvite, {
    mutationKey: ['org-decline-invitation', token],
    onError: (err) => errorHandler(err),
  });

export const useVerifyToken = ({ token, enabled }: { token: string; enabled: boolean }) =>
  useQuery({
    queryKey: ['org-verify-token', { token }],
    queryFn: () => API.organizations.verifyToken(token).then((res) => res.data),
    onError: (err) => errorHandler(err),
    enabled,
  });

export const useJoinOrganization = () =>
  useMutation(({ id }: { id: number }) => API.organizations.join(id), {
    mutationKey: ['join-org'],
    onError: (err) => errorHandler(err),
  });

export const useUpdateMemberStatus = () =>
  useMutation(
    ({ id, status, from }: { id: number; status: string; from: string }) =>
      API.organizations.memberStatus(id, { status, from }),
    {
      mutationKey: ['update-member-status'],
      onError: (err) => errorHandler(err),
    },
  );

export const useDismissActivationComponentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: { id: number }) => {
      queryClient.invalidateQueries(['org', { id }]);
      return API.organizations.dismissActivationComponent(id);
    },
    {
      mutationKey: ['org-dismiss-activation-component'],
      onError: (err) => errorHandler(err),
    },
  );
};

export const useInvalidateApprovedOrInvitedMembers = (orgId: number) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['organizations.getApprovedOrInvitedMembers', orgId]);
    queryClient.invalidateQueries(['org', { orgId }]);
    queryClient.invalidateQueries(['invitations.getPendingOrgInvites', orgId]);
  };
};

export const useGetReducedOrganizationByMagicLinkToken = (token: string | undefined) =>
  useQuery({
    queryKey: ['org', { token }],
    queryFn: async (): Promise<Organization> => {
      const res = await API.organizations.getReducedOrganizationByMagicLinkToken(token!);
      return res.data;
    },
    enabled: !!token,
  });

export type AdminResponse = {
  id: number;
  userId: number;
  user: {
    id: number;
    firstName?: string;
    lastName?: string;
    gender?: string;
    avatarUrl?: string;
    emailAddress?: string;
  };
  organizationId: number;
  type: 'admin' | 'support' | 'consultant';
};

export const useOrganizationAdmins = (orgId: number) =>
  useQuery({
    queryKey: ['org-admins', orgId],
    queryFn: async () => {
      const { data } = await http().get<AdminResponse[]>(
        `/api/v4/workspaces/${orgId}/access`,
      );
      return data;
    },
  });

export const useRemoveAdmin = ({ orgId }: { orgId: number }) => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['org-admin-remove', orgId],
    mutationFn: async (data: { orgId: number; accessId: number }) => {
      const { data: resData } = await http().delete<AdminResponse>(
        `/api/v4/workspaces/${data.orgId}/access/${data.accessId}`,
      );
      return resData;
    },
    onError: (err) => errorHandler(err),
    onSuccess: () => {
      client.invalidateQueries(['org-admins', orgId]);
    },
  });
};

type OrgAdminInviteReq = {
  accessType: 'admin' | 'support' | 'consultant';
  data: Array<
    { emailAddress: string; userId?: number } | { emailAddress?: string; userId: number }
  >;
};
export const useInviteAdmin = ({ orgId }: { orgId: number }) => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['org-admin-invite', orgId],
    mutationFn: async (invites: OrgAdminInviteReq) => {
      const { data: resData } = await http().post<AdminResponse>(
        `/api/v4/workspaces/${orgId}/access/`,
        invites,
      );
      return resData;
    },
    onError: (err) => errorHandler(err),
    onSuccess: () => {
      client.invalidateQueries(['org-admins', orgId]);
    },
  });
};
