import { z } from 'zod';

import { ShareSchema } from './share';

export const reportCreateRequestSchema = z
  .object({
    title: z.string(),
    prompt: z.string(),
    status: z.enum(['pending', 'ready']),
    pendingReason: z.enum([
      'motivation',
      'invite',
      'credit',
      'moderation',
      'invite_team',
    ]),
    askMarleeTemplateId: z.coerce.number(),
  })
  .partial();
export type ReportCreateRequestData = z.infer<typeof reportCreateRequestSchema>;

export const reportUpdateRequestSchema = z
  .object({
    title: z.string(),
    prompt: z.string(),
    status: z.enum(['pending', 'ready']),
    pendingReason: z.enum([
      'motivation',
      'invite',
      'credit',
      'moderation',
      'invite_team',
    ]),
    askMarleeTemplateId: z.coerce.number(),
  })
  .partial();
export type ReportUpdateRequestData = z.infer<typeof reportCreateRequestSchema>;

// TODO: Revise/Rename these - based on types in apps/web/src/modules/report/queries.ts

// Recursive type definition for childSections using z.lazy
// See: https://github.com/colinhacks/zod#recursive-types
type baseReportSection = z.infer<typeof baseReportSection> & {
  childSections: baseReportSection[];
};
const baseReportSection = z.object({
  id: z.number(),
  createdDate: z.coerce.date(),
  updatedDate: z.coerce.date().optional(),
  type: z.enum(['motivations', 'motivation_group', 'benchmark', 'culture']).nullable(),
  title: z.string().optional(),
  body: z.string().optional(),
  motivationGroupId: z.number().nullable(),
});
export type ReportSection = baseReportSection;
export const ReportSectionSchema: z.ZodType<z.infer<typeof baseReportSection>> =
  baseReportSection.extend({
    childSections: z.lazy(() => ReportSectionSchema.array()),
  });

export type Report = z.infer<typeof ReportSchema>;
export const ReportSchema = z.object({
  id: z.number(),
  ownerId: z.number().optional(),
  status: z.enum(['pending', 'ready', 'processing', 'done']).optional(),
  pendingReason: z
    .enum(['motivation', 'invite', 'invite_team', 'credit', 'moderation'])
    .nullish(),
  pendingMessage: z.string().nullable().nullish(),
  type: z.enum(['my_results']).nullish(),
  createdDate: z.coerce.date().optional(),
  updatedDate: z.coerce.date().optional(),
  title: z.string().nullish(),
  prompt: z.string().nullish(),
  askMarleeQuestion: z
    .object({
      id: z.number(),
      feedbackIsPositive: z.boolean().nullable(),
      feedbackComment: z.string().nullable(),
      templateId: z.number().nullable(),
    })
    .nullish(),
  askMarleeTemplateId: z.number().nullish(),
  sections: ReportSectionSchema.array(),
  shares: ShareSchema.array().optional(),
});

const ReportMinimalSchema = z.object({
  id: z.number(),
  title: z.string().nullish(),
  createdDate: z.coerce.date().optional(),
});
export type ReportMinimal = z.infer<typeof ReportMinimalSchema>;
