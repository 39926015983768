import { z, type Schema } from 'zod';

type ObjectName = 'dashboard';

const baseSchema = z.object({
  templateSlug: z.string().optional(),
  dashboardTitle: z.string().optional(),
  includesSelf: z.boolean().optional(),
  datasetCount: z.number().optional(),
});

const widgetSchema = baseSchema.extend({
  widgetTitle: z.string().optional(),
  widgetType: z.string().optional(),
});

const dashboardSchemas = {
  dashboard_created: baseSchema,
  dashboard_updated: baseSchema,
  dashboard_deleted: baseSchema,
  dashboard_shared: baseSchema,
  dashboard_viewed: baseSchema,
  dashboard_templateViewed: baseSchema,
  dashboard_widgetViewed: widgetSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { dashboardSchemas };
