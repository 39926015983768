import { z } from 'zod';

export const TeamCreateSchema = z.object({
  workspaceId: z.number(),
  name: z.string(),
  memberIds: z.array(z.number()),
});
export type TeamCreate = z.infer<typeof TeamCreateSchema>;

export const TeamUpdateSchema = z.object({
  workspaceId: z.number(),
  teamId: z.number(),
  name: z.string().optional(),
  avatarUrl: z.string().optional(),
  memberIds: z.array(z.number()).optional(),
});
export type TeamUpdate = z.infer<typeof TeamUpdateSchema>;

export const TeamDeleteSchema = z.object({
  workspaceId: z.number(),
  teamId: z.number(),
});
export type TeamDelete = z.infer<typeof TeamDeleteSchema>;

export type MinimalUser = {
  userId: number;
  firstName: string | null;
  lastName: string | null;
  avatarUrl?: string | null;
};
