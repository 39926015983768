import { z, type Schema } from 'zod';

type ObjectName = 'login';

const method = z.enum([
  'password',
  'oauth_google',
  'oauth_facebook',
  'token',
  'sso',
  'code',
]);
const reason = z.enum([
  'noEmailSupplied',
  'noPasswordSupplied',
  'noPasswordSet',
  'noToken',
  'noCode',
  'noSession',
  'tokenUsedOrNotFound',
  'incorrectPassword',
  'incorrectEmail',
  'oauthMissingEmail',
  'oauthMatchesMultipleUsers',
  'restrictedToSSO',
  'accountDisabled',
  'ssoNotAMember',
  'ssoConfigError',
  'serverError',
  'unknown',
]);
export type AuthErrorReason = z.infer<typeof reason>;

const loginSchemas = {
  login_success: z.object({ method }),
  login_failed: z.object({
    method,
    reason,
  }),
  login_resetRequested: z.object({}).optional(),
  login_resetCompleted: z.object({}).optional(),
  login_loggedOut: z.object({}).optional(),
  login_expired: z.object({}).optional(),
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { loginSchemas };
